<template>
  <div class="md:px-16 md:my-8 relative">
    <div>
      <BreadCrumbs class="" />
      <div v-if="loading">
        <Loading />
      </div>
      <div
        v-else-if="product && selectedVariant"
        class="md:flex md:justify-around lg:justify-between lg:px-10"
      >
        <!-- top -->
        <div class="md:flex">
          <div class="rounded text-center">
            <h3 class="font-medium md:hidden mb-2">
              {{ product.title }}
            </h3>
            <div class="flex">
              <div
                class="h-64 md:h-96 md:w-96 lg:h-128 lg:w-128 md:rounded shadow-md overflow-hidden"
              >
                <carousel
                  :per-page="1"
                  :navigateTo="[variantIndex, false]"
                  :mouse-drag="false"
                  :autoplay="false"
                  :touchDrag="true"
                  paginationColor="#ffffff"
                  paginationActiveColor="#ffffff"
                >
                  <slide
                    v-for="loopVariant in product.variants"
                    :key="loopVariant._id"
                    class="relative"
                  >
                    <div
                      v-if="loopVariant.image"
                      class="w-64 h-64 md:w-76 md:h-96 lg:w-96 lg:h-128 mx-auto flex items-center justify-center overflow-hidden cursor-pointer bg-no-repeat bg-contain bg-center"
                      :style="`background-image: url('${loopVariant.image}');`"
                      @click="toggleModal(loopVariant.image)"
                    ></div>
                    <div
                      v-else
                      class="w-1/2 h-64 sm:w-1/4 md:h-96 md:w-48 lg:w-64 lg:h-128 mx-auto flex items-center justify-center overflow-hidden cursor-pointer bg-no-repeat bg-contain bg-center"
                      style="background-image: url('/images/icons/image-coming-soon.png')"
                    ></div>
                    <div v-if="loopVariant.label" class="absolute right-1 top-1 text-gray-500">
                      {{ loopVariant.label }}
                    </div>

                    <button
                      v-if="loopVariant.favorite"
                      @click.stop="unFavorite(loopVariant)"
                      class="w-12 absolute right-1 bottom-1"
                    >
                      <img
                        class="w-full"
                        src="/images/icons/heart-solid.png"
                        alt="Favorited symbol"
                      />
                    </button>
                    <button
                      v-else
                      @click.stop="favorite(loopVariant)"
                      class="w-12 absolute right-1 bottom-1"
                    >
                      <img
                        class="w-full"
                        src="/images/icons/heart-outline.png"
                        alt="Favorited symbol"
                      />
                    </button>
                  </slide>
                </carousel>
              </div>
              <div class="hidden md:block md:ml-12 lg:ml-24">
                <!-- Start of breadcrumbs and item info for desktop -->
                <div class="md:mt-12 lg:mt-20 md:pb-2 font-medium hidden md:flex">
                  <h2>{{ product.title }}</h2>
                  <div>
                    <router-link
                      v-if="authorized"
                      tag="button"
                      :to="`/admin/products/${product._id}`"
                      class="ml-2 mt-2 p-2 hover:bg-blue-200 rounded"
                    >
                      <img src="/images/icons/Edit.svg" alt="Edit" width="25px" />
                    </router-link>
                  </div>
                </div>

                <Pricing class="mt-6" :variant="selectedVariant" :product="product" />
                <!-- End Pricing -->

                <AddToCart
                  :variantGroup="variantGroup"
                  :product="product"
                  :variant="selectedVariant"
                />
              </div>
            </div>

            <!-- options -->
            <div class="text-center mt-4 md:mt-10 mb-4 md:ml-0 lg:max-w-4xl" :key="variantIndex">
              <Options
                :product="product"
                @changeVariant="changeVariant"
                :variantIndex="variantIndex"
                :variantGroupIndex="variantGroup"
              />
            </div>
            <div class="hidden md:grid grid-cols-2 gap-8 text-left">
              <div v-if="product.description" :class="product.video ? '' : 'col-span-2 max-w-3xl '">
                <div class="font-medium md:mr-4 text-lg mt-1">{{ product.title }} Description</div>
                <div class="" v-html="product.description"></div>
              </div>
              <div
                v-if="product.video"
                :class="product.description ? '' : 'col-span-2'"
                class="flex justify-center"
              >
                <iframe
                  class="w-full h-1/2-screen md:h-2/3-screen"
                  :src="product.video.replace('watch?v=', 'embed/')"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <!-- End Options -->
          </div>
        </div>

        <!-- This is the product details for the mobile -->
        <div class="md:hidden">
          <!-- Start Pricing -->
          <Pricing class="mt-6" :variant="selectedVariant" :product="product" />
          <!-- End Pricing -->

          <AddToCart
            :variantGroup="variantGroup"
            class="md:hidden"
            :product="product"
            :variant="selectedVariant"
          />
          <!-- End add to cart -->
          <div class="md:hidden mx-4 bg-gray-200 px-2 py-2 mb-4 rounded" v-if="product.description">
            <button @click="changeDescription" class="w-full text-left font-medium">
              <font-awesome-icon icon="chevron-down" class="icon mx-2" id="description" />
              Description
            </button>
            <transition name="expandDown">
              <div v-if="showDescription" class="px-4 py-2" v-html="product.description"></div>
            </transition>
          </div>
          <div v-if="product.video" class="px-4">
            <iframe
              class="w-full h-1/2-screen md:w-1/2 md:h-2/3-screen"
              :src="product.video.replace('watch?v=', 'embed/')"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="2xl:block hidden" v-if="product.collections && product.collections.length > 0">
          <CollectionWrapper :collections="product.collections" :product_id="product._id" />
        </div>
        <button
          v-if="product.collections && product.collections.length > 0"
          class="2xl:hidden hidden md:block absolute -right-2 md:-right-2 top-10 md:top-1 bg-action -rotate-90 transform text-white p-2 rounded-t-lg"
          @click="showCollection = !showCollection"
          :style="showCollection ? 'right: 330px' : ''"
        >
          Collection
        </button>
        <div
          v-if="showCollection"
          class="fixed 2xl:hidden right-0 top-0 z-5 z-10 pt-24 shadow-md bg-gray-200 h-screen p-3 overflow-y-auto"
          id="collection"
        >
          <CollectionWrapper :collections="product.collections" :product_id="product._id" />
        </div>
      </div>
      <div v-else class="text-center p-8">
        <h3>No product found</h3>

        <div class="text-center w-full">
          <div><router-link to="/categories">shop other products</router-link></div>
          <img
            src="/images/static/empty.svg"
            class="w-64 h-64 mx-auto mt-4"
            alt="Image of an empty cart"
          />
        </div>
      </div>

      <!-- Suggested -->
      <div class="flex mx-4 mt-8">
        <div class="font-medium">Suggested</div>
        <div class="ml-auto">
          <router-link
            :to="{ path: '/products', query: { type: 'suggested', categories: 'Suggested' } }"
            >View all</router-link
          >
        </div>
      </div>
      <product-carousel :products="suggestions" :loading="suggestedLoading" />

      <!-- End Suggested -->
    </div>

    <ReviewWrapper
      :average="product ? product.averageRating : 0"
      :reviews="product ? product.reviews : []"
    />
    <!-- Large image modal -->
    <Modal @close="showModal = false" v-if="showModal">
      <div class="max-h-screen overflow-x-auto pt-2">
        <img v-lazy="modalImage" />
      </div>
    </Modal>
    <Modal @close="showNotSignedIn = false" v-if="showNotSignedIn" title="Not Signed in">
      <div class="text-center">
        <strong>You aren't signed in!</strong><br />In order to favorite/unfavorite items you must
        be signed in.
      </div>
      <div class="grid grid-cols-2 my-4">
        <router-link tag="button" to="/user" class="btn-white mx-2 md:mx-8 rounded">
          Sign Up
        </router-link>
        <router-link tag="button" to="/user" class="btn-action mx-2 md:mx-8 rounded">
          Sign In
        </router-link>
      </div>
    </Modal>
    <Modal v-if="showGetProductLabel" @close="showGetProductLabel = false" title="Label">
      <div class="text items-center">
        <div>Add a label to easily reference this product.</div>
        <div class="flex justify-between items-center mt-4">
          <div>
            <label for="label" class="block text-sm font-medium text-gray-700">Label</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                name="label"
                id="label"
                :class="
                  isLabelError
                    ? 'border-red-300 text-red placeholder-red-300 focus:border-red-500 focus:ring-red-500 '
                    : ''
                "
                class="block w-full pr-10 focus:outline-none sm:text-sm rounded-md"
                placeholder="Johnson kitchen"
                v-model="favoriteLabel"
                aria-invalid="true"
                aria-describedby="email-error"
              />
              <div
                class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                v-if="isLabelError"
              >
                <!-- Heroicon name: solid/exclamation-circle -->
                <svg
                  class="h-5 w-5 text-red"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <p class="mt-2 text-sm text-red" id="email-error" v-if="isLabelError">
              Label must be less than 16 characters long.
            </p>
          </div>
          <div v-if="!isLabelError">
            <button @click="addToFavorites" class="btn-primary rounded">Add Favorite</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import ProductCarousel from '@/components/items/ProductCarousel';
import BreadCrumbs from '@/components/shared/BreadCrumbs.vue';
import { Carousel, Slide } from 'vue-carousel';
import Loading from '@/components/shared/Loading';
import Modal from '@/components/shared/PopUp';
import Options from '@/components/items/ProductOptions';
import Pricing from '@/components/items/ProductPricing';
import AddToCart from '@/components/items/AddToCart';
import ReviewWrapper from '@/components/reviews/ReviewWrapper.vue';
import CollectionWrapper from '@/components/collections/CollectionWrapper.vue';
export default {
  data: () => {
    return {
      colors: new Map(),
      selectedVariant: null,
      trueVal: true,
      variantIndex: 0,
      variantGroup: null,
      showDescription: false,
      showModal: false,
      showNotSignedIn: false,
      modalImage: null,
      showCollection: false,
      showGetProductLabel: false,
      favoriteLabel: null,
    };
  },
  computed: {
    suggestedLoading() {
      return this.$store.getters['products/getSuggestedLoading'];
    },
    suggestions() {
      if (this.suggestedLoading) {
        return [];
      }
      const suggestionSet = new Set();
      const suggestions = [];

      if (this.product.suggestions) {
        for (const s of this.product.suggestions) {
          if (!suggestionSet.has(s._id.toString())) {
            suggestionSet.add(s._id.toString());
            suggestions.push(s);
          }
        }
      }
      const globalSuggestions = this.$store.getters['products/getSuggested'];

      for (const s of globalSuggestions) {
        if (!suggestionSet.has(s._id.toString())) {
          suggestionSet.add(s._id.toString());
          suggestions.push(s);
        }
      }

      return suggestions;
    },
    user() {
      return this.$store.state.user;
    },
    commercial() {
      return this.$store.getters.getCommercial;
    },
    authorized() {
      return this.$store.getters.getAuthorized;
    },
    loading() {
      return this.$store.getters['products/getLoading'];
    },
    product() {
      return this.$store.getters['products/getProduct'];
    },
    isLabelError() {
      if (!this.favoriteLabel) return false;
      if (this.favoriteLabel.length > 15) return true;
      return false;
    },
  },
  watch: {
    $route() {
      this.fetchProduct();
    },
    loading() {
      this.setSelectedVariant();
    },
  },
  methods: {
    favorite(variant) {
      if (!this.user) {
        this.showNotSignedIn = true;
        return;
      }
      this.addToFavoritesVariant = variant;
      this.showGetProductLabel = true;
    },
    addToFavorites() {
      this.$store.dispatch('user/addToFavorites', {
        product_id: this.product._id,
        variant_id: this.addToFavoritesVariant._id,
        quantity: this.addToFavoritesVariant.quantity,
        label: this.favoriteLabel,
      });
      this.addToFavoritesVariant.favorite = true;
      this.addToFavoritesVariant = null;
      this.favoriteLabel = null;
      this.showGetProductLabel = false;
      this.$forceUpdate();
    },
    unFavorite(variant) {
      if (!this.user) {
        this.showNotSignedIn = true;
        return;
      }
      this.$store.dispatch('user/removeFromFavorites', {
        product_id: this.product._id,
        variant_id: variant._id,
        quantity: variant.quantity,
      });
      variant.favorite = false;
      this.$forceUpdate();
    },

    changeVariant({ selectedIndex, selectedGroup }) {
      this.variantIndex = selectedIndex;
      this.variantGroup = selectedGroup;
      this.selectedVariant = this.product.variants[this.variantIndex];
    },
    setSelectedVariant() {
      if (this.product) {
        this.changeVariant({ selectedIndex: 0, selectedGroup: null });

        if (this.$route.query.variants) {
          for (let i = 0; i < this.product.variants.length; i++) {
            if (this.$route.query.variants === this.product.variants[i]._id) {
              this.changeVariant({ selectedIndex: i, selectedGroup: null });
              return;
            }
          }
        } else {
          this.changeVariant({ selectedIndex: 0, selectedGroup: null });
        }
      }
    },
    async fetchProduct() {
      try {
        this.$store.dispatch('filter/getSingleTemplate', '?name:%20Color');

        let val = null;
        if (this.$route.query.variants) {
          val = this.$route.query.variants
            ? this.$route.query.productNum
              ? `${this.$route.params.id}?variants=${this.$route.query.variants}&productNum=${this.$route.query.productNum}`
              : `${this.$route.params.id}?variants=${this.$route.query.variants}`
            : this.$route.params.id;
        } else {
          val = this.$route.params.id;
        }

        await this.$store.dispatch('products/getProduct', val);
        if (this.$route.query.variants) {
          this.setSelectedVariant();
        }
        this.$forceUpdate();
      } catch (error) {
        console.log(error);
      }
    },
    changeDescription() {
      const icon = document.getElementById('description');

      if (this.showDescription) {
        icon.style.transform = 'rotate(0deg)';
      } else {
        icon.style.transform = 'rotate(180deg)';
      }

      this.showDescription = !this.showDescription;
    },
    toggleModal(image) {
      this.modalImage = image;
      this.showModal = true;
    },
  },
  components: {
    'product-carousel': ProductCarousel,
    BreadCrumbs,
    Carousel,
    Slide,
    Loading,
    Modal,
    Options,
    Pricing,
    AddToCart,
    CollectionWrapper,
    ReviewWrapper,
  },
  mounted() {
    this.fetchProduct();
  },
  metaInfo() {
    const product = this.product;

    if (product)
      return {
        title: `${product.title} - ${product.categories[0]} - ${product.subcategories[0]}`,
        meta: [
          { name: 'description', content: `${product.description}` },
          { name: 'keywords', content: 'online hardware supply' },
        ],
      };
  },
};
</script>
<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.color-tool-tip {
  display: none;
}

@media only screen and (min-width: 900px) {
  .color-tool-tip-wrapper:hover .color-tool-tip {
    display: block;
  }
}
</style>
