<template>
  <div class="mx-auto max-w-sm">
    <div class="text-xl text-center mb-4">Products in this collection</div>
    <div
      v-if="collectionLoading"
      class="flex flex-col items-center overflow-hidden overflow-y-auto scrollbar-w-2 px-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray"
    >
      <div>
        <ProductLoading v-for="index in 3" :key="`${index}-product`" />
      </div>
    </div>
    <div
      class="flex flex-col items-center overflow-hidden overflow-y-auto scrollbar-w-2 px-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray"
      v-else
      style="height: 700px"
    >
      <div v-for="(product, index) in products" :key="`${index}-product`">
        <ProductTile
          v-if="product_id !== product._id"
          :productInfo="product"
          :index="index"
          :length="products.length"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductTile from '@/components/items/ProductTile.vue';
import ProductLoading from '@/components/shared/LoadingProduct';

export default {
  props: {
    collections: Array,
    product_id: String,
  },
  data() {
    return {};
  },
  computed: {
    products() {
      return this.$store.getters['products/getCollectionProducts'];
    },
    collectionLoading() {
      return this.$store.getters['products/getCollectionLoading'];
    },
  },
  methods: {
    fetchData() {
      let payload = '';
      this.collections.forEach((el) => {
        payload += `collections=${el}&`;
      });
      this.$store.dispatch('products/getCollectionProducts', payload);
    },
  },
  mounted() {
    this.fetchData();
  },
  components: {
    ProductTile,
    ProductLoading,
  },
};
</script>

<style></style>
