<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="bg-white relative">
    <div
      class="max-w-full mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:py-32 lg:px-8 lg:grid lg:grid-cols-12 lg:gap-x-8"
    >
      <div class="lg:col-span-4">
        <CumulativeReviews :average="average" :totalCount="totalCount" :counts="counts" />
      </div>
      <div class="mt-16 lg:mt-0 lg:col-start-6 lg:col-span-7">
        <h3 class="sr-only">Recent reviews</h3>

        <div class="flow-root">
          <div class="-my-12 divide-y divide-gray-200">
            <div v-if="reviews.length > 0">
              <SingleReview
                v-for="review in reviews"
                :key="review._id"
                :review="review"
                :isCurrentUser="user_id === review.user_id"
                :employee="employee"
              />
            </div>
            <div v-else>No reviews have been posted, be the first!</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CumulativeReviews from './CumulativeReviews.vue';
import SingleReview from './SingleReview.vue';

export default {
  props: {
    average: { type: Number },
    reviews: { type: Array },
  },
  data() {
    return {};
  },
  computed: {
    counts: {
      get() {
        const counts = [
          { rating: 1, count: 0 },
          { rating: 2, count: 0 },
          { rating: 3, count: 0 },
          { rating: 4, count: 0 },
          { rating: 5, count: 0 },
        ];

        if (this.reviews)
          for (const review of this.reviews) {
            if (counts[review.rating - 1]) counts[review.rating - 1].count++;
          }
        return counts;
      },
    },
    totalCount() {
      if (this.reviews) {
        return this.reviews.length;
      }
      return 0;
    },
    user_id: {
      get() {
        return this.$store.getters['user/getUserId'];
      },
    },
    employee: {
      get() {
        return this.$store.getters['getAuthorized'];
      },
    },
  },
  components: {
    CumulativeReviews,
    SingleReview,
  },
};
</script>

<style></style>
