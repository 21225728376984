<template>
  <div class="">
    <h2 class="text-2xl font-extrabold tracking-tight text-gray-900">Customer Reviews</h2>

    <div class="mt-3 flex items-center">
      <div>
        <div class="flex items-center">
          <div v-for="rating in [0, 1, 2, 3, 4]" :key="rating">
            <svg
              class="flex-shrink-0 h-5 w-5"
              :class="average > rating ? 'text-action' : 'text-gray-300'"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
          </div>
        </div>
        <p class="sr-only">{{ average }} out of 5 stars</p>
      </div>
      <p class="ml-2 text-sm text-gray-900">Based on {{ totalCount }} reviews</p>
    </div>

    <div class="mt-6">
      <h3 class="sr-only">Review data</h3>

      <dl class="space-y-3" v-if="counts">
        <div v-for="count in counts" :key="count.rating" class="flex items-center text-sm">
          <dt class="flex-1 flex items-center">
            <p class="w-3 font-medium text-gray-900">
              {{ count.rating }}<span class="sr-only"> star reviews</span>
            </p>
            <div aria-hidden="true" class="ml-1 flex-1 flex items-center">
              <svg
                class="flex-shrink-0 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                :class="count.count > 0 ? 'text-action' : 'text-gray-300'"
              >
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                />
              </svg>

              <div class="ml-3 relative flex-1">
                <div class="h-3 bg-gray-100 border border-gray-200 rounded-full" />
                <div
                  v-if="count.count > 0"
                  class="absolute inset-y-0 bg-action border border-action rounded-full"
                  :style="`width: calc(${count.count} / ${totalCount} * 100%)`"
                />
              </div>
            </div>
          </dt>
          <dd class="ml-3 w-10 text-right tabular-nums text-sm text-gray-900">
            {{ totalCount ? Math.round((count.count / totalCount) * 100) : 0 }}%
          </dd>
        </div>
      </dl>
    </div>

    <div class="mt-10">
      <div v-if="leaveReview" class="w-full bg-white flex flex-col space-y-2">
        <div class="flex">
          <div v-for="rating in [0, 1, 2, 3, 4]" :key="rating">
            <svg
              class="flex-shrink-0 h-10 w-10 text-gray-300 cursor-pointer"
              :class="isHovered(rating) ? 'text-action' : null"
              @click="setHover(rating + 1)"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
          </div>
        </div>
        <div>
          <div class="w-full">
            <textarea class="w-full rounded border p-1 bg-white" v-model="content" />
          </div>
        </div>
      </div>
      <div v-else>
        <h3 class="text-lg font-medium text-gray-900">Share your thoughts</h3>
        <p class="mt-1 text-sm text-gray-600">
          If you've used this product, share your thoughts with other customers
        </p>
      </div>

      <button
        v-if="leaveReview"
        type="button"
        @click="postReview()"
        class="mt-6 inline-flex w-full bg-white border border-gray-300 rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full"
      >
        Post review
      </button>
      <button
        v-else
        type="button"
        @click="leaveReview = !leaveReview"
        class="mt-6 inline-flex w-full bg-white border border-gray-300 rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full"
      >
        Write a review
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    average: { type: Number, default: 0 },
    totalCount: { type: Number, default: 10 },
    counts: { type: Array },
  },
  data() {
    return {
      leaveReview: false,
      content: null,
      selectedRating: 0,
    };
  },
  methods: {
    setHover(val) {
      this.selectedRating = val;
    },
    isHovered(val) {
      if (this.selectedRating > val) {
        return true;
      }
      return false;
    },
    postReview() {
      if (!this.content) {
        this.$store.commit('setError', {
          color: 'red',
          message: "can't post a review without text",
          duration: 6500,
        });
        return;
      }
      if (!this.selectedRating) {
        this.$store.commit('setError', {
          color: 'red',
          message: "can't post a review without a rating",
          duration: 6500,
        });
        return;
      }

      this.leaveReview = false;
      this.$store.dispatch('reviews/createReview', {
        product_id: this.$route.params.id,
        rating: this.selectedRating,
        content: this.content,
      });
    },
  },
};
</script>

<style></style>
