<template>
  <div class="py-6 relative">
    <div class="flex items-center">
      <img
        v-if="review.image"
        :src="review.image"
        :alt="`${review.author}.`"
        class="h-12 w-12 rounded-full"
      />
      <div v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-12 w-12 rounded-full"
          viewBox="0 0 20 20"
          fill="gray"
        >
          <path
            fill-rule="evenodd"
            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="ml-4">
        <h4 class="text-sm font-bold text-gray-900">{{ review.author }}</h4>
        <div class="mt-1 flex items-center">
          <div v-for="rating in [0, 1, 2, 3, 4]" :key="rating">
            <svg
              class="h-5 w-5 flex-shrink-0"
              :class="review.rating > rating ? 'text-action' : 'text-gray-300'"
              @click="setHover(rating + 1)"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
              />
            </svg>
          </div>
        </div>
        <p class="sr-only">{{ review.rating }} out of 5 stars</p>
      </div>
    </div>
    <div class="absolute right-0 top-0" v-if="isCurrentUser">
      <button @click="deleteReview()" v-if="editReview" class="text-red">delete</button>
      <button @click="editReview = !editReview" v-else class="text-gray-600">edit</button>
    </div>
    <div class="mt-4 space-y-6 text-base italic text-gray-600" v-if="!editReview">
      {{ review.content }}
    </div>
    <input
      type="text-area"
      v-else
      v-model="review.content"
      class="mt-4 space-y-6 p-4 text-base text-gray-600 border border-gray-300 w-full rounded"
    />
    <div class="flex justify-end" v-if="editReview">
      <button type="button" @click="saveReview()">Save</button>
    </div>
    <div class="mt-4 ml-4 md:ml-8 space-y-6 text-base text-gray-600" v-if="review.response">
      <div class="flex items-center">
        <img
          v-if="review.response.image"
          :src="review.response.image"
          :alt="`${review.response.author}.`"
          class="h-12 w-12 rounded-full"
        />
        <div v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-12 w-12 rounded-full"
            viewBox="0 0 20 20"
            fill="gray"
          >
            <path
              fill-rule="evenodd"
              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-4">
          <h4 class="text-sm font-bold text-gray-900">
            {{ review.response.author }}
            <span class="text-xs text-gray-400 font-thin">employee</span>
          </h4>
          <div class="mt-1 flex items-center">
            {{ review.response.content }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="employee && showAddResponse" class="ml-4">
      <input
        type="text-area"
        v-model="employeeResponse"
        class="mt-4 space-y-6 p-4 text-base text-gray-600 border border-gray-300 w-full rounded"
      />
      <div class="flex justify-end">
        <button type="button" @click="addResponse()">Save</button>
      </div>
    </div>
    <div v-if="employee && !showAddResponse" class="flex justify-end">
      <button type="button" @click="showAddResponse = !showAddResponse">add comment</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editReview: false,
      employeeResponse: null,
      showAddResponse: false,
    };
  },
  props: {
    review: [String, Object],
    isCurrentUser: { type: Boolean, default: false },
    employee: { type: Boolean, default: false },
  },
  methods: {
    saveReview() {
      this.$store.dispatch('reviews/updateReview', this.review);
      this.editReview = false;
    },
    deleteReview() {
      this.$store.dispatch('reviews/deleteReview', this.review._id);
    },
    setHover(val) {
      if (this.editReview) this.review.rating = val;
    },
    addResponse() {
      const user = this.$store.getters['user/getUser'];
      this.review.response = {
        content: this.employeeResponse,
        image: user.avatar,
        author: user.name,
      };

      this.showAddResponse = false;
      this.$store.dispatch('reviews/updateReview', this.review);
    },
  },
};
</script>

<style></style>
